import "../Styles/MainComponent.css";

const MainComponent = () => {
  return (
    <div className="main-element">
      <h1>linkwb.io</h1>
      <p>
        created by <b>Musket Industries</b>
      </p>
    </div>
  );
};

export default MainComponent;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./Styles/App.css";

import MainComponent from "./Components/MainComponent";

import RedirectHandler from "./Handlers/RedirectHandler";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:shortUrl" element={<RedirectHandler />} />
        <Route path="/" element={<MainComponent />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;

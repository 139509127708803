import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import ErrorComponent from "../Components/ErrorComponent";

const RedirectHandler = () => {
  const { shortUrl } = useParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/url/link/${shortUrl.toLowerCase()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_API_SECRET_KEY}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data && data.redirect) {
            window.location.href = data.redirect;
          }
        } else {
          setError(true);
          console.error("Nie odnaleziono takiego odnośnika! Spróbuj ponownie!");
        }
      } catch (error) {
        setError(true);
        console.error(
          "Wystąpił błąd podczas ładowania serwerów API! Spróbuj ponownie!"
        );
      }
    };

    fetchData();
  }, [shortUrl]);

  if (error) {
    return <ErrorComponent />;
  }

  return null;
};

export default RedirectHandler;

import "../Styles/ErrorComponent.css";

const ErrorComponent = () => {
  return (
    <div className="error-element">
      <div className="error-content">
        <img src="../assets/images/error/error-image.png" alt="test" />
        <h1>¯\_(ツ)_/¯</h1>
        <span>Wystąpił błąd podczas ładowania strony!</span>
        <span>Upewnij się, że podany przez Ciebie link jest prawidłowy</span>
      </div>
    </div>
  );
};

export default ErrorComponent;
